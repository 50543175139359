const freezeBlock = (block, topNeighbour, newEl) => {
    // Вставляем временный элемент и замораживаем основной блок
    block.before(newEl);
    topNeighbour.style.top = `${topNeighbour.getBoundingClientRect().top}px`;
    topNeighbour.classList.add('fixed');
    block.style.transform = 'scale(1)';
}

const removeFreezeBlock = (newEl, topNeighbour) => {
    // Удаляем временный элемент и размораживаем основной блок
    topNeighbour.classList.remove('fixed');
    newEl.remove();
}
export const infinityScrollPage = (block) => {
    const topNeighbour = block.previousElementSibling;
    const topNeighbourHeight = topNeighbour.getBoundingClientRect().height;
    const heightWindow = document.documentElement.clientHeight;

    // Создаем временный элемент
    let newEl = document.createElement('div');
    newEl.setAttribute('style', `height: ${topNeighbourHeight}px`);

    window.addEventListener('scroll', () => {
        const positionBlock = block.getBoundingClientRect().top;

        // Эффект размытия и трансформации
        const blurSize = window.innerWidth > 800 ? (5 - (10 - positionBlock / heightWindow * 10) / 2) : window.innerWidth > 700 ? (20 - (10 - positionBlock / heightWindow * 10) / 2) : (positionBlock / heightWindow * 10) / 4;

        if (positionBlock <= heightWindow + 100 && positionBlock > 0) {
            block.style.clipPath = `polygon(0 ${blurSize}%, 100% 0, 100% 100%, 0 100%)`;
        }
    });
}
