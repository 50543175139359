<script>
import {defineComponent} from 'vue'
import titleSection from "@/components/blocks/titleSection.vue";
import BlockquoteComponent from "@/components/blocks/blockquoteComponent.vue";
import {infinityScrollPage} from "@/assets/js/infinityScroll"
import Timeline from "@/components/layouts/timeline/timelineWrapper.vue";
import BlockPhoto from "@/components/blocks/blockPhoto.vue";
import VideoComponent from "@/components/blocks/videoComponent.vue";

export default defineComponent({
  name: "secondChapter",
  components: {VideoComponent, BlockPhoto, Timeline, BlockquoteComponent, titleSection},
  data() {
    return {
      menu: [
        {
          link: 'podpisaniye-dogovorov-o-poyezdkakh-v-kosmos',
          title: 'Подписание договоров о сотрудничестве в космосе',
        },
        {
          link: 'mezhdunarodnyye-dostizheniya-v-oblasti-kosmosa',
          title: 'Международные достижения в области космоса',
        },
        {
          link: 'kosmos-nachal-kommertsializirovatsya',
          title: 'Космос начал коммерциализироваться',
        },
      ]
    }
  },
  mounted() {
    infinityScrollPage(this.$el);
  }
})
</script>

<template>
  <div class="block-chapter">
    <video-component src="assets/video/chapter_2.mp4" poster="assets/video/chapter_2.webp" type="mp4"></video-component>
    <title-section
        src="mezhdunarodnoye-sotrudnichestvo-v-kosmose">
      <template v-slot:title>Международное сотрудничество в&nbsp;космосе</template>
      <template v-slot:subheading>
        Одним из&nbsp;ключевых факторов на&nbsp;пути освоения космоса является международное сотрудничество. Ведь именно с&nbsp;помощью открытого диалога и&nbsp;синергии знаний и&nbsp;опыта нескольких
        стран мировое сообщество добилось больших побед в&nbsp;исследовании космического пространства и&nbsp;создании межгосударственных космических проектов, влияющих на&nbsp;будущее нашей Планеты.
      </template>
    </title-section>

    <!--    Section 1-->
    <div class="container">
      <h3 class="text-wrapper" id="podpisaniye-dogovorov-o-poyezdkakh-v-kosmos">Подписание договоров о&nbsp;сотрудничестве в&nbsp;космосе</h3>
      <p class="text-wrapper">
        Так, во&nbsp;второй половине&nbsp;ХХ века, в&nbsp;1967 году было подписано первое международное соглашение&nbsp;&mdash; Договор о&nbsp;космосе, провозгласивший равенство стран в&nbsp;исследовании
        и&nbsp;использовании космического пространства, включая Луну и&nbsp;другие небесные тела. В&nbsp;соответствии с&nbsp;данным Договором странам запрещается размещать в&nbsp;космическом
        пространстве любые виды оружия массового уничтожения (ОМУ), а&nbsp;также осуществлять деятельность, влекущую неблагоприятные изменения земной среды или создающую потенциально вредные помехи
        другому государству. Ответственность за&nbsp;несоблюдение положений Договора о&nbsp;космосе полностью возлагается на&nbsp;государство в&nbsp;независимости от&nbsp;того, осуществляется
        космическая деятельность правительственными или неправительственными организациями, однако, как и&nbsp;многие нормативные акты ООН, документ не&nbsp;содержит конкретных санкций за&nbsp;нарушение
        этих условий.
      </p>
    </div>

    <blockquote-component img="assets/img/2-chapter_1.webp" :blockquote="false" :shadow="true">
      <template v-slot:blockquote>
        Помимо основополагающего документа о&nbsp;сотрудничестве в&nbsp;космосе Российская Федерация также заключила отдельные двусторонние соглашения с&nbsp;ведущими космическими державами.
      </template>
    </blockquote-component>

    <div class="container">
      <p class="text-wrapper">
        Так, например, история взаимоотношений между Россией и&nbsp;США в&nbsp;исследовании космического пространства началась в&nbsp;1962&nbsp;году, когда было подписано межагентсткое соглашение
        Академии наук СССР и&nbsp;NASA &laquo;О&nbsp;сотрудничестве в&nbsp;использовании искусственных спутников для метеорологии и&nbsp;сверхдальней связи, составлении карты магнитного поля Земли&raquo;.
        Это был первый и&nbsp;единственный документ, заключенный США с&nbsp;иностранным государством, который подразумевал обмен материалами космических исследований в&nbsp;интересах науки и&nbsp;образцами
        лунного грунта в&nbsp;будущем. Данное событие повлекло за&nbsp;собой ряд совместных советско-американских проектов и&nbsp;договоренностей, таких как &laquo;Союз&nbsp;&mdash; Аполлон&raquo;,
        работа на&nbsp;орбитальной станции &laquo;Мир&raquo;, а&nbsp;в&nbsp;дальнейшем и&nbsp;создание Международной космической станции.
      </p>
      <p class="text-wrapper">
        В&nbsp;настоящее время страны придерживаются действующего соглашения 1992 года о&nbsp;сотрудничестве в&nbsp;исследовании и&nbsp;использовании космического пространства в&nbsp;мирных целях.
      </p>
      <p class="text-wrapper">
        Российско-китайское сотрудничество в&nbsp;космической отрасли зародилось в&nbsp;1990-х годах после подписания ряда межправительственных и&nbsp;межведомственных соглашений об&nbsp;исследовании
        космического пространства (1992&nbsp;года), о&nbsp;совместной деятельности в&nbsp;области пилотируемой космонавтики (1994&nbsp;года) и&nbsp;соответствующих протоколов, но&nbsp;наиболее тесное
        взаимодействие пришлось на&nbsp;вторую половину 2000-х годов.
      </p>
      <p class="text-wrapper">
        В&nbsp;2008 году обеспокоенные возможным повторением холодной войны китайское и&nbsp;российское правительства представили на&nbsp;заседании ООН совместный проект договора о&nbsp;предотвращении
        размещения оружия в&nbsp;космическом пространстве, применения силы или угрозы силой в&nbsp;отношении космических объектов (ДПРОК), который должен был остановить гонку вооружений и&nbsp;стремление
        &laquo;отдельных государств и&nbsp;групп государств&raquo; к&nbsp;доминированию в&nbsp;космосе. Однако до&nbsp;сих пор эта инициатива не&nbsp;была реализована.
      </p>
      <p class="text-wrapper">
        В&nbsp;связи с&nbsp;агрессией США в&nbsp;отношении России после событий 2014 года на&nbsp;Украине Москва начала постепенный процесс переориентации на&nbsp;Восток и&nbsp;развития всесторонних
        отношений с&nbsp;Пекином. Так, помимо четырех заключенных соглашений о&nbsp;сотрудничестве в&nbsp;космосе, в&nbsp;2018 году было подписано межправительственное соглашение о&nbsp;сотрудничестве
        в&nbsp;области применения глобальных навигационных спутниковых систем ГЛОНАСС и&nbsp;&laquo;Бэйдоу&raquo; в&nbsp;мирных целях, а&nbsp;в&nbsp;2022 году&nbsp;&mdash; о&nbsp;сотрудничестве по&nbsp;созданию
        Международной научной лунной станции (МНЛС).
      </p>
    </div>
    <!--    END Section 1-->

    <!--    Section 2-->
    <div class="container">
      <h3 class="text-wrapper" id="mezhdunarodnyye-dostizheniya-v-oblasti-kosmosa">Международные достижения в&nbsp;области космоса</h3>
      <p class="text-wrapper">
        В&nbsp;1993 году начался процесс создания Международной космической станции (МКС)&nbsp;&mdash; как компромисс между 14&nbsp;государствами на&nbsp;фоне дороговизны содержания собственной
        орбитальной станции и&nbsp;отсутствия у&nbsp;некоторых стран необходимых технологий и&nbsp;знаний для самостоятельной реализации данного проекта. Так, 15&nbsp;марта 1993 года представители
        Российского космического агентства и&nbsp;научно-производственного объединения &laquo;Энергия&raquo; обратились к&nbsp;NASA с&nbsp;предложением о&nbsp;создании МКС, а&nbsp;уже 2&nbsp;сентября
        было подписано соответствующее межправительственное соглашение, положившее начало самому крупному на&nbsp;сегодняшний день международному космическому проекту, объединившему ресурсы,
        научно-технические достижения и&nbsp;опыт России и&nbsp;западных стран.
      </p>
      <p class="text-wrapper">
        Несмотря на&nbsp;то, что МКС является единственным масштабным и&nbsp;успешным международным проектом, объединившим специалистов из&nbsp;более десяти стран, сейчас наиболее эффективным
        вариантом межправительственного взаимодействия считаются двусторонние инициативы.
      </p>
      <p class="text-wrapper">
        Так, в&nbsp;результате совместных усилий двух корпораций Европейского космического агентства (ЕКА) и&nbsp;госкорпорации &laquo;Роскосмос&raquo; в&nbsp;марте 2016 года была реализована
        совместная астробиологическая программа &laquo;ЭкзоМарс&raquo;. Данный проект был нацелен на&nbsp;исследование планеты Марс, её&nbsp;поверхности, недр и&nbsp;пригодности к&nbsp;проживанию. Для
        этого были изготовлены марсианский орбитальный аппарат TGO и&nbsp;десантный модуль &laquo;Скиапарелли&raquo;, однако в&nbsp;ходе плановой посадки модуль потерпел аварию и&nbsp;был потерян.
      </p>
      <p class="text-wrapper description-photo">
        На&nbsp;сегодняшний день реализация второго этапа &laquo;ЭкзоМарс&raquo;, запланированного на&nbsp;2022&nbsp;год, приостановлена, а&nbsp;проект свернут по&nbsp;политическим причинам.
      </p>

      <block-photo img="assets/img/exomars.webp"></block-photo>

      <p class="text-wrapper">
        Еще одним примером продуктивного сотрудничества является проект России и&nbsp;Германии по&nbsp;созданию астрофизической обсерватории. В&nbsp;июле 2019 года в&nbsp;космос была запущена
        космическая обсерватория &laquo;Спектр-Рентген-Гамма&raquo; (СРГ), включающая два уникальных телескопа, работающих по&nbsp;принципу рентгеновской оптики косого падения. Плодотворная работа
        российских и&nbsp;германских ученых по&nbsp;разработке СРГ внесла огромный вклад в&nbsp;развитие рентгеновской астрономии и&nbsp;изучение темной материи и&nbsp;энергии, так как предоставила
        возможность создать видимую картину Вселенной, на&nbsp;которой будут отмечены все крупные скопления галактик.
      </p>
    </div>
    <!--    END Section 2-->

    <!--    Section 3-->
    <div class="container">
      <h3 class="text-wrapper" id="kosmos-nachal-kommertsializirovatsya">Космос начал коммерциализироваться</h3>
      <p class="text-wrapper">
        В&nbsp;настоящее время все больше негосударственных компаний начали интересоваться развитием космической отрасли за&nbsp;счет частных инвестиций в&nbsp;данные проекты. С&nbsp;2013 по&nbsp;2022
        годы инвесторы вложили в&nbsp;космическую индустрию более $270&nbsp;млрд. Разработка новых космических, суборбитальных и&nbsp;орбитальных аппаратов, посадочных модулей и&nbsp;спутников
        расширяет возможности исследования космоса, делает этот процесс более доступным, по&nbsp;сравнению с&nbsp;прошлым веком, и&nbsp;прозрачным, ввиду открытости данных о&nbsp;пусках и&nbsp;необходимых
        затратах на&nbsp;реализацию проектов. Одними из&nbsp;самых успешных космических компаний в&nbsp;мире являются SpaceX, Blue Origin, Lockheed Martin, Scaled Composites и&nbsp;Space Pioneer.
        Наряду с&nbsp;зарубежными гигантами рынка в&nbsp;России также существуют и&nbsp;успешно тестируются космические программы отечественных компаний, такие как ракета-носитель Stalker от&nbsp;SR&nbsp;Space,
        спутник дистанционного зондирования Земли &laquo;Зоркий&raquo;, космический аппарат CHALLENGEONE от&nbsp;Спутникс и&nbsp;сверхлегкие ракеты-носители &laquo;Таймыр&raquo; и&nbsp;&laquo;Сибирь&raquo;
        стартапа Лин Индастриал.
      </p>
    </div>
    <blockquote-component img="assets/img/putin.webp" color="var(--goldColor)" :invertColor="true">
      <template v-slot:blockquote>
        Это [низкий космос] очень интересное и&nbsp;перспективное направление. Вы&nbsp;правы абсолютно, когда говорите, что ниша не&nbsp;занята и&nbsp;нужно активно здесь работать, пока она не&nbsp;занята.
      </template>
      <template v-slot:sign>
        <a href="https://ru.wikipedia.org/wiki/%D0%9F%D1%83%D1%82%D0%B8%D0%BD,_%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80_%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87"
           class="link" target="_blank">Владимир Путин</a> &mdash; Президент Российской Федерации
      </template>
    </blockquote-component>
    <div class="container">
      <p class="text-wrapper">
        Однако, помимо научной и&nbsp;познавательной целей создания космических аппаратов, некоторые компании решили внедрить новое направление, привлекающее к&nbsp;себе огромный интерес со&nbsp;стороны
        мирового сообщества&nbsp;&mdash; космический туризм. Несмотря на&nbsp;то, что Россия была первой страной в&nbsp;мире, которая запустила в&nbsp;космос туриста (2001&nbsp;год), в&nbsp;настоящее
        время форвардами в&nbsp;этой сфере являются три зарубежные компании: SpaceX Илона Маска, Blue Origin Джеффа Безоса и&nbsp;Virgin Galactic Ричарда Брэнсона, на&nbsp;счету которых в&nbsp;совокупности
        более 10&nbsp;успешных полетов с&nbsp;туристами на&nbsp;борту.
      </p>
    </div>
    <!--    END Section 3-->
    <timeline :menu="menu" v-transfer-dom></timeline>
  </div>
</template>

<style scoped lang="scss">
.block-chapter {
  position: relative;
  background: var(--darkColor);
  color: var(--darkColor);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("~@/assets/img/noise_2.webp");
    opacity: .4;
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--darkGoldColor);
    opacity: .6;
    z-index: -1;
  }

  .video {
    z-index: -2;
  }
}

::v-deep {
  h2, .subheading {
    color: var(--darkColor);
  }
}


h3 {
  color: var(--lightColor);
}

::v-deep blockquote, .description-photo {
  color: var(--lightColor)
}

</style>
