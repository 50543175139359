<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "stars"
})
</script>

<template>
  <div class="stars-wrapper anim" id="stars">
    <div class="star"></div>
    <div class="star2"></div>
    <div class="twinkling"></div>
  </div>
</template>

<style scoped lang="scss">

</style>
