<script>
import {defineComponent} from 'vue';
import PreloaderComponent from "@/components/layouts/preloaderComponent.vue";

export default defineComponent({
  name: "Main",
  components: {PreloaderComponent},
  props: {
    isLoading: true,
  },
  methods: {
    hiddenMain() {
      if (!this.isLoading) {
        const topCoordinateEl = this.$el.nextSibling.getBoundingClientRect().top;
        if (topCoordinateEl <= window.innerHeight * .4) {
          this.$el.classList.add('hidden');
        } else {
          this.$el.classList.remove('hidden');
        }
      }
    },
  },
  mounted() {
    this.hiddenMain();
    window.addEventListener('scroll', this.hiddenMain);
  }
})
</script>

<template>
  <section class="cover">
    <preloader-component></preloader-component>
    <h1>
      <span id="new">Новая</span>
      <span id="space">космическая</span>
      <span id="race">гонка</span>
    </h1>
  </section>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
@import "~@/assets/style/utils/mixins";

.cover {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: $mobile) {
    //height: 35vh;
  }

  &.hidden {
    opacity: 0;
    transition: .2s opacity;
  }
}


h1 {
  text-align: center;
  font-size: calc(1rem + 8.35vw);
  line-height: .8;
  letter-spacing: 0.4vw;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: 6s showTitle 3s forwards;
  @include no-cpu;
  opacity: 0;
  transition: 1s opacity;

  @media (max-width: $mobile) {
    margin-top: 5vw;
    font-size: calc(1rem + 8vw);
  }

  &:before,
  &:after {
    position: absolute;
    font-family: var(--ff-sansSerif);
    font-size: calc(1rem + 17vw);
    font-weight: 900;
    z-index: -1;
    color: var(--darkColor);
    text-shadow: 0 0 1px rgba(white, .8);
    opacity: .5;
    transition: 1s opacity;
    letter-spacing: 0;

    @media (max-width: $mobile) {
      font-size: calc(1rem + 9vw);
    }
  }

  &::before {
    transform: translate(35%, -38%);
    right: 0;
    top: 0;
    content: '1957';

    @media (max-width: $mobile) {
      transform: translate(9%, 4%);
    }
  }

  &::after {
    transform: translate(-32%, 65%);
    left: 0;
    bottom: 0;
    content: '2024';

    @media (max-width: $mobile) {
      transform: translate(-8%, 38%);
    }
  }


  span {
    display: block;
    white-space: nowrap;
  }

}

#new {
  text-align: left;
  position: relative;
  left: calc(1rem + 4vw);
}

#space {
  text-align: center;
}

#race {
  text-align: right;
  position: relative;
  right: calc(1rem + 5vw);
}


@keyframes showTitle {
  from {
    top: 60%;
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.2);
  }

  to {
    top: 50%;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

</style>
