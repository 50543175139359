<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "timeline",
  props: {
    menu: {
      type: Array,
      default: null,
    }
  },
  data() {
    return {
      isActiveItem: null,
      listItemsTimeline: [],
      intervalsChapters: [],
      positionHeadings: [],
      districtScroll: 'bottom',
      oldPositionScroll: 0,
    }
  },
  methods: {
    getActiveChapter() {
      const parent = this.$el.parentNode;
      this.getListItemsTimeline();

      this.menu.forEach((item, index) => {
        const id = item.link;
        const heading = parent.querySelector(`#${id}`);
        this.positionHeadings.push(heading.getBoundingClientRect().top);
        this.intervalsChapters.push(this.getInterval(index, heading, parent));
        let scrollItem = false;

        window.addEventListener('scroll', () => {
          if (heading.getBoundingClientRect().top <= window.innerHeight - 100 && heading.getBoundingClientRect().top + this.intervalsChapters[index] > window.innerHeight) {
            this.isActiveItem = id;
            if (index !== 0) {
              this.scrollingItemTimeline(index, scrollItem);
              scrollItem = true;
            }
          } else {
            scrollItem = false;
          }
        })
      })
    },
    getInterval(indexItem, headingItem, parent) {
      const nextId = this.menu[indexItem + 1] ? this.menu[indexItem + 1].link : null;

      if (nextId) {
        const nextHeadingTopPosition = parent.querySelector(`#${nextId}`).getBoundingClientRect().top;
        const headingTopPosition = headingItem.getBoundingClientRect().top;
        return nextHeadingTopPosition - headingTopPosition;
      }

      return parent.getBoundingClientRect().top + parent.getBoundingClientRect().height - headingItem.getBoundingClientRect().top;
    },
    getListItemsTimeline() {
      this.listItemsTimeline = this.$refs.itemsMenu.querySelectorAll('.line');
    },
    scrollingItemTimeline(index, scrollItem) {
      if (!scrollItem && this.$refs.itemsMenu) this.$refs.itemsMenu.scrollBy({
        left: this.districtScroll === 'bottom' ? this.listItemsTimeline[index - 1].getBoundingClientRect().width : -1 * this.listItemsTimeline[index - 1].getBoundingClientRect().width,
        top: 0,
        behavior: "smooth"
      });
    },
    getDistrictScroll() {
      this.districtScroll = this.oldPositionScroll < document.body.getBoundingClientRect().top ? 'top' : 'bottom';
      this.oldPositionScroll = document.body.getBoundingClientRect().top;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.getDistrictScroll);
    this.getActiveChapter();
  }
})
</script>

<template>
  <div class="timeline">
    <div class="line-items" ref="itemsMenu">
      <div class="line" v-for="item in menu" :key="item.link" :class="{'active': isActiveItem === item.link}">
        <a :href="'#' + item.link">{{ item.title }}</a>
      </div>
      <div class="circle" ref="circle"></div>
    </div>

  </div>
</template>

<style scoped lang="scss">

.timeline {
  display: flex;
  $gray: 200;
  border-radius: 4rem;
  position: fixed;
  padding: .25rem .25rem .75rem;
  bottom: 0;
  left: 50%;
  opacity: 0;
  transform: translate(-50%);
  width: 880px;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  backdrop-filter: blur(23px);
  box-shadow:
  //inset 0 22px 50px -20px rgba(255, 255, 255, .2),
  inset 0 1px 0 0 rgba(255, 255, 255, .35),
  //inset 0 -22px 50px -20px rgba(255, 255, 255, .2),
  inset 0 -1px 0 0 rgba($gray, $gray, $gray, .1),
  inset 1px 0 0 0 rgba($gray, $gray, $gray, .1),
  inset -1px 0 0 -1px rgba($gray, $gray, $gray, .1),
  0 30px 30px 0 rgba(34, 34, 34, .1);
  transition: bottom .5s, opacity .5s;

  @media (max-width: 900px) {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--goldColor);
    background: white;
    opacity: .1;
    z-index: -2;
  }

  &.show {
    bottom: 2rem;
    opacity: 1;
  }
}

.line-items {
  position: relative;
  display: flex;
  overflow: scroll;
  padding-left: 20px;
  padding-bottom: 2px;
  -ms-overflow-style: none;
  min-width: 100%;
  mask-image: linear-gradient(90deg, transparent, white 3%, white 90%, transparent);
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.circle {
  --sizeTimeline: 5px;
  position: absolute;
  left: 18px;
  bottom: 3px;
  width: var(--sizeTimeline);
  height: var(--sizeTimeline);
  background: white;
  z-index: 2;
  border-radius: 100%;
}

.line {
  position: relative;
  flex: 1 0 100px;
  margin: 0 2px 0 0;
  padding: 0 3rem 0 0;
  $gray: 255;
  text-align: left;
  --sizeTimeline: 3px;

  &::before {
    content: '';
    position: absolute;
    left: -1px;
    bottom: 2px;
    width: var(--sizeTimeline);
    height: var(--sizeTimeline);
    background: var(--lightColor);
    box-shadow: 0 0 0 3px rgba(white, .2);
    z-index: 2;
    border-radius: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3px;
    border-bottom: 1px solid rgb($gray, $gray, $gray);
    opacity: .2;
  }

  a {
    position: relative;
    top: -.25rem;
    color: var(--lightColor);
    cursor: pointer;
    font-weight: 400;
    text-decoration: none;
    font-size: 12px;
    letter-spacing: .2px;
    white-space: nowrap;
    z-index: 4;
    opacity: .5;
    transition: color .2s, opacity .2s;

    &:hover {
      opacity: 1;
    }
  }

  &.active {
    a {
      color: white;
      opacity: 1;
    }
  }
}
</style>
