<script>
import {defineComponent} from 'vue'
import Timeline from "@/components/layouts/timeline/timeline.vue";

export default defineComponent({
  name: "timelineWrapper",
  props: {
    menu: {
      type: Array,
      default: null,
    }
  },
  components: {Timeline},
  data() {
    return {
      isTimeline: false,
    }
  },
  methods: {
    showTimeline() {
      const parent = this.$el.parentNode;

      window.addEventListener('scroll', () => {
        if (parent.getBoundingClientRect().top <= window.innerHeight / 2 && parent.getBoundingClientRect().top + parent.getBoundingClientRect().height >= window.innerHeight * 2.5) {
          if(!this.isTimeline) {
            this.isTimeline = true;

            setTimeout(() => {
              this.$refs.timeline.$el.classList.add('show');
            }, 500)
          }
        } else {
          if (this.isTimeline) {
            this.$refs.timeline.$el.classList.remove('show');
            setTimeout(() => {
              this.isTimeline = false;
            }, 500)
          }
        }
      })
    },
  },
  mounted() {
    this.showTimeline();
  }
})
</script>

<template>
  <timeline v-if="isTimeline" :menu="menu" ref="timeline"></timeline>
</template>

<style scoped lang="scss">

</style>
