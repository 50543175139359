<script>
import {defineComponent} from 'vue'
import StippleImg from "@/components/effects/stippleImg.vue";
import BlockquotePhoto from "@/components/blocks/blockquotePhoto.vue";

export default defineComponent({
  name: "blockquoteComponent",
  props: {
    blockquote: {
      type: Boolean,
      default: true,
    },
    img: null,
    invertColor: false,
    shadow: false,
    color: {
      type: String,
      default: "var(--darkColor)",
    },
  },
  components: {BlockquotePhoto, StippleImg}
})
</script>

<template>
  <div class="blockquote container">
    <blockquote-photo
        :invertColor="invertColor"
        :img="img"
    ></blockquote-photo>
    <blockquote>
      <p :class="{'quote': blockquote}">
        <slot name="blockquote"></slot>
      </p>
      <footer>
        <slot name="sign"></slot>
      </footer>
    </blockquote>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

.blockquote {
  margin: 0 auto 1rem;
}


blockquote {
  grid-column-start: 1;
  grid-column-end: 13;
  font-family: var(--ff-serif);
  font-weight: 700;
  font-size: calc(1rem + .75vw);
  line-height: 1.6;
  letter-spacing: .2px;
  margin-left: 0;
  margin-right: 0;
  padding-top: 2rem;
  text-align: center;
  z-index: 1;

  @media (max-width: $desktopMin) {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  @media (max-width: $tablet) {
    grid-column-start: 1;
    grid-column-end: 13;
    font-weight: 600;
    font-size: calc(1rem + .5vw);
    line-height: 1.8;
    font-optical-sizing: auto;
  }

  @media (max-width: $mobile) {
    text-align: left;
    //font-size: 1.2rem;
    //line-height: 1.5rem;
    font-size: calc(1rem + .6vw);
    line-height: 1.5;
  }

  p {
    position: relative;
    word-break: break-word;
    hyphens: none;

    &.quote::before {
      content: '«';
      line-height: 2rem;
      font-size: 2rem;
    }

    &.quote::after {
      content: '»';
      font-size: 2rem;
      line-height: 2rem;
    }

    @media (max-width: 860px) {
      &.quote::before,
      &.quote::after {
        position: static;
        font-size: 1rem;
        line-height: inherit;
        margin: 0;
      }
    }
  }

  footer {
    font-family: var(--ff-sansSerif);
    font-style: italic;
    font-size: calc(1rem - .2vw);
    line-height: 1.6;
    font-weight: 200;
    letter-spacing: 0;
    margin: 1.4rem 0 0;
    color: rgba(white, .7);
    cursor: pointer;

    .link {
      font-weight: 500;
    }

    @media (max-width: 860px) {
      margin: .6rem 0 0;

    }
  }
}


</style>
