<script>
import {defineComponent} from 'vue'
import EarthComponent from "@/components/blocks/earthComponent.vue";

export default defineComponent({
  name: "preloaderComponent",
  components: {EarthComponent}
})
</script>

<template>
  <div id="preloader">
    <div class="preloader-background"></div>
    <earth-component></earth-component>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
@import "~@/assets/style/utils/mixins";

#preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--darkColor);
    animation: 4s hiddenBg 1s forwards;
    @include no-cpu(opacity);
  }
}


@keyframes hiddenBg {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


</style>
