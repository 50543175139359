<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "titleSection",
  props: {
    translate: {
      type: Boolean,
      default: true,
    },
    src: null,
  }
})
</script>

<template>
  <div class="title-section cover-section" :id="src">
    <div class="container" >
      <div class="text-wrapper" :class="{'translate': translate}">
        <h2>
            <slot name="title"></slot>
        </h2>
        <p class="subheading">
          <slot name="subheading"></slot>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

.cover-section {
  padding: 30vh 0 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //min-height: 50vh;
  height: auto;
  scroll-margin: 0;

  @media (max-width: $tablet) {
    height: auto;
    padding: 20vh 0 10vh;
  }

  @media (max-width: $mobile) {
    height: auto;
    padding: 20vh 0 10vh;
  }
}

.translate {
  position: relative;
  //left: -6rem;

  .subheading {
    position: relative;
    //right: -12rem;
  }
}


</style>
