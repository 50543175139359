<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "menu-component",
  data() {
    return {
      menu: [
        {
          link: 'pervyye-kosmicheskiye-derzhavy',
          title: 'Первые космические державы',
          parts: [
            {
              link: 'kholodnaya-voyna',
              title: 'Холодная война',
            },
            {
              link: 'raketostroitelnaya-promyshlennost',
              title: 'Ракетостроительная промышленность',
            },
            {
              link: 'pervyy-chelovek-v-kosmose',
              title: 'Первый человек в космосе',
            },
            {
              link: 'apollo',
              title: 'Apollo',
            },
            {
              link: 'rukopozhatiye-v-kosmose',
              title: '«Рукопожатие в космосе»',
            },
          ]
        },
        {
          link: 'mezhdunarodnoye-sotrudnichestvo-v-kosmose',
          title: 'Международное сотрудничество в космосе',
          parts: [
            {
              link: 'podpisaniye-dogovorov-o-poyezdkakh-v-kosmos',
              title: 'Подписание договоров о сотрудничестве в космосе',
            },
            {
              link: 'mezhdunarodnyye-dostizheniya-v-oblasti-kosmosa',
              title: 'Международные достижения в области космоса',
            },
            {
              link: 'kosmos-nachal-kommertsializirovatsya',
              title: 'Космос начал коммерциализироваться',
            },
          ]
        },
        {
          link: 'militarizatsiya-kosmosa',
          title: 'Милитаризация космоса',
          parts: [
            {
              link: 'USA',
              title: 'Соединенные Штаты Америки',
            },
            {
              link: 'China',
              title: 'Китайская Народная Республика',
            },
            {
              link: 'Russia',
              title: 'Российская Федерация',
            },
          ]
        },
        {
          link: 'vozobnovleniye-kosmicheskoy-gonki',
          title: 'Возобновление космической гонки',
          parts: [
            {
              link: 'chastnyye-kompanii',
              title: 'Частные компании и их вклад в реализацию государственной политики',
            },
            {
              link: 'novyye-strany',
              title: 'Новые страны вступают в космическую гонку',
            },
          ]
        },
        {
          link: 'primeneniye-kosmicheskikh-apparatov',
          title: 'Применение космических аппаратов',
          parts: [
            {
              link: 'vedeniye-boyevykh-deystviy-i-kosmicheskiye-rassledovaniya',
              title: 'Ведение боевых действий и космические расследованияи',
            },
            {
              link: 'kommunikatsiya-i-navigatsiya',
              title: 'Коммуникация и навигация',
            },
            {
              link: 'nablyudeniye-za-zemley',
              title: 'Наблюдение за Землей: геология, метеорология и ЧС',
            },
          ]
        }
      ]
    }
  }
})
</script>

<template>
  <div class="menu">
    <div class="menu__list">
      <div class="chapter" v-for="(chapter, index) in menu" :key="chapter.link">
        <a :href='`#${chapter.link}`' class="link-chapter" @click="$emit('hiddenMenu')">{{ chapter.title }}</a>
        <div class="parts__list">
          <a :href='`#${part.link}`' class="link-part" v-for="(part, index) in chapter.parts" :key="part.link" @click="$emit('hiddenMenu')">
            {{ part.title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
.menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  $time: 1s;
  pointer-events: none;

  &::after {
    content: '';
    position: fixed;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    height: 0;
    width: 0;
    background: var(--orangeColor);
    z-index: 1;
    border-radius: 100%;
    opacity: 0;
    transition: width $time / 2, height $time / 2, opacity $time / 2, filter $time;
    filter: blur(100px);
  }

  &.active {
    pointer-events: auto;
    &::after {
      $size: 500vw;
      filter: blur(0px);
      transition: width $time, height $time, opacity $time, filter $time;
      opacity: 1;
      height: $size;
      width: $size;
    }

    .menu__list {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      left: 50%;
      transition: .4s opacity .5s, left 1s;
    }
  }
}

.menu__list {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  left: 60%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  width: 80vw;
  flex-direction: column;
  transition: opacity .25s, left .5s;

  @media (max-width: $mobile) {
    width: 90vw;
  }
}

.link-chapter {
  display: flex;
  gap: 0 1rem;
  align-items: center;
  justify-content: left;
  font-size: calc(1rem + 1.05vw);
  line-height: 1rem;
  font-weight: 900;
  font-family: var(--ff-serif);
  color: #f7ce83;
  text-decoration: none;
  position: relative;
  text-align: left;

  &::after {
    content: '';
    height: 1px;
    opacity: .6;
    border-bottom: 1px solid var(--lightColor);
    position: relative;
    flex: 1 1;
    top: .2rem;
    left: 0;
    right: 0;
    z-index: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.parts__list {
  display: flex;
  margin: calc(.005rem + 1vw) 0 calc(1rem + 2vw);
  flex-direction: column;
}

.link-part {
  font-size: calc(.75rem + .1vw);
  margin: calc(.005rem + .25vw) 0;
  line-height: 1rem;
  font-weight: 400;
  color: var(--darkColor);
  text-decoration: none;
  text-align: left;
}

a {
  transition: color .3s, margin .3s;

  &:hover {
    color: white;
    margin-left: -1rem;
  }
}
</style>
