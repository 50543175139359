<script>
import {defineComponent} from 'vue'
import titleSection from "@/components/blocks/titleSection.vue";
import {infinityScrollPage} from "@/assets/js/infinityScroll";
import Timeline from "@/components/layouts/timeline/timelineWrapper.vue";
import Stars from "@/components/effects/stars.vue";
import VideoComponent from "@/components/blocks/videoComponent.vue";
import EarthComponent from "@/components/blocks/earthComponent.vue";
import BlockPhoto from "@/components/blocks/blockPhoto.vue";

export default defineComponent({
  name: "fifthChapter",
  components: {BlockPhoto, EarthComponent, VideoComponent, Stars, Timeline, titleSection},
  data() {
    return {
      menu: [
        {
          link: 'vedeniye-boyevykh-deystviy-i-kosmicheskiye-rassledovaniya',
          title: 'Ведение боевых действий и космические расследованияи',
        },
        {
          link: 'kommunikatsiya-i-navigatsiya',
          title: 'Коммуникация и навигация',
        },
        {
          link: 'nablyudeniye-za-zemley',
          title: 'Наблюдение за Землей: геология, метеорология и ЧС',
        },
      ]
    }
  },
  mounted() {
    infinityScrollPage(this.$el)
  }
})
</script>

<template>
  <div class="block-chapter">
    <video-component src="assets/video/chapter_5.mp4" poster="assets/video/chapter_5.webp"></video-component>
    <title-section :translate="false" src="primeneniye-kosmicheskikh-apparatov">
      <template v-slot:title>Применение космических аппаратов</template>
    </title-section>

    <!--    Section 1-->
    <div class="container">
      <h3 class="text-wrapper" id="vedeniye-boyevykh-deystviy-i-kosmicheskiye-rassledovaniya">Ведение боевых действий и&nbsp;космические расследования</h3>
      <p class="text-wrapper subheading">
        Главным образом спутники создавались для обеспечения суверенитета и&nbsp;достижения доминирующих позиций страны в&nbsp;космическом пространстве. Изображения, сделанные со&nbsp;спутников,
        позволяли анализировать особенности местности при планировании операций.
      </p>
      <block-photo img="assets/img/map.webp">
      </block-photo>
      <p class="text-wrapper">
        Однако для успешного выполнения боевых задач бывает недостаточно задействовать аэрокосмическую фотосъемку. Поэтому
        зачастую используются технологии искусственного интеллекта, который позволяет в&nbsp;автоматическом режиме сравнивать полученные изображения, определять изменения и&nbsp;возможные передвижения
        и&nbsp;места дислокации наземных или военно-морских группировок войск и&nbsp;сигнализировать о&nbsp;них центры принятия решений.
      </p>
      <p class="text-wrapper">
        В&nbsp;свою очередь передача сообщений о&nbsp;дальнейших действиях и&nbsp;последующая их&nbsp;координация, так&nbsp;же, как и&nbsp;коммуникация между различными боевыми подразделениями и&nbsp;соединениями
        осуществляются через спутниковую связь, что является незаменимым инструментом в&nbsp;условиях современных военных конфликтов, таких как операции США в&nbsp;Афганистане и&nbsp;Ираке,
        российско-украинский конфликт на&nbsp;Донбассе или кризис в&nbsp;Секторе Газа.
      </p>
      <p class="text-wrapper">
        Спутниковые аппараты также востребованы и&nbsp;в&nbsp;вопросах определения признаков правонарушения из&nbsp;космоса. Например, космическая фотосъемка способна предоставить доказательства
        незаконного использования земельных ресурсов, занятия земель, которые предназначены для коммерческих, сельскохозяйственных целей или являющихся охраняемыми. Данные со&nbsp;спутников также
        будут полезны при расследованиях случаев незаконной добычи полезных ископаемых, вырубки лесов, контрабанды, неправомерной утилизации отходов, нелегального пересечения государственных границ и&nbsp;так
        далее.
      </p>
      <p class="text-wrapper">
        Так, например, с&nbsp;2016 по&nbsp;2019 года Следственный комитет России около 175 раз запрашивал спутниковые данные для расследования преступлений, а&nbsp;в&nbsp;Сирии с&nbsp;помощью
        аэрофотосъемки были найдены и&nbsp;проанализированы места массовых захоронений, которая стала основанием начала расследования об&nbsp;убийствах мирных жителей.
      </p>
    </div>
    <!--    END Section 1-->


    <!--    Section 2-->
    <div class="container">
      <h3 class="text-wrapper" id="kommunikatsiya-i-navigatsiya">Коммуникация и&nbsp;навигация</h3>
      <p class="text-wrapper subheading">
        На&nbsp;сегодняшний день на&nbsp;земной орбите находится около 7&nbsp;000 искусственных спутников, однако только 40%&nbsp;&mdash; действующие, а&nbsp;остальные 60%&nbsp;&mdash; космический
        мусор. Из&nbsp;них более половины были запущены в&nbsp;коммерческих целях, а&nbsp;именно для обеспечения всех видов связи.
      </p>
      <block-photo img="assets/img/chapter_5-2.png">
      </block-photo>
      <p class="text-wrapper">
        За&nbsp;последние 15&nbsp;лет космические спутники стали неотъемлемой частью нашей жизни, они ежедневно обеспечивают стабильную и&nbsp;бесперебойную работу телевидения, навигационных систем и&nbsp;систем
        связи, необходимых как для работы, так и&nbsp;в&nbsp;личных целях. Благодаря спутникам появилась возможность мгновенно обмениваться сообщениями или отправлять электронные письма и&nbsp;медиафайлы,
        совершать звонки из&nbsp;любой точки мира или следить за&nbsp;прямой трансляцией, планировать маршруты для будущей поездки или отслеживать местоположение груза с&nbsp;помощью спутниковых
        навигационных систем (GPS, ГЛОНАСС, Galileo и&nbsp;др.) в&nbsp;режиме реального времени, имея только доступ в&nbsp;Интернет или телефонную связь.
      </p>
      <p class="text-wrapper">
        Лидерами в&nbsp;данной сфере являются США, Китай, Великобритания, ЕС&nbsp;и&nbsp;Россия&nbsp;&mdash; по&nbsp;состоянию на&nbsp;2023 год количество запущенных ими спутников составило 6&nbsp;262.
        А&nbsp;самым крупным коммерческим оператором спутниковой связи является американская компания SpaceX, которая с&nbsp;2019 года произвела 128&nbsp;запусков, в&nbsp;том числе 59&nbsp;только в&nbsp;минувшем
        году. В&nbsp;настоящее время на&nbsp;орбите находится более 5,1&nbsp;тыс. аппаратов Starlink в&nbsp;рабочем состоянии.
      </p>
    </div>
    <!--    END Section 2-->

    <!--    Section 3-->
    <div class="container">
      <h3 class="text-wrapper" id="nablyudeniye-za-zemley">Наблюдение за&nbsp;Землей: <br> геология, метеорология и&nbsp;ЧС</h3>
      <p class="text-wrapper subheading">
        Спутники используются для наблюдения за&nbsp;Землей из&nbsp;космоса, они оснащены камерами, радарами и&nbsp;спектрометрами, которые позволяют собирать информацию о&nbsp;распределении облаков,
        температуре поверхности, состоянии растительности, уровне загрязнения.
      </p>
      <block-photo img="assets/img/chapter_5-3.png">
      </block-photo>
      <p class="text-wrapper">
        Эти данные могут использоваться для прогнозирования погоды, изучения изменений в&nbsp;окружающей среде, а&nbsp;также для
        множества других целей, включая аграрные и&nbsp;геологические исследования. Они также могут отслеживать озоновые дыры, загрязнения, рост городов, то&nbsp;есть служить целям городского
        планирования, урбанистики, землеустройства и&nbsp;строительства. Более того современные спутники позволяют снимать высококачественные изображения земной поверхности, которые помогают геологам
        анализировать геологические структуры и&nbsp;определять состав горных пород и&nbsp;минеральных ресурсов на&nbsp;больших территориях, что облегчает поиск месторождений полезных ископаемых.
      </p>
      <p class="text-wrapper">
        Особое значение играет возможность наблюдения за&nbsp;Землей из&nbsp;космоса при чрезвычайных ситуациях. Помимо мониторинга погодных условий, температуры, осадков и&nbsp;прогнозирования
        развития опасных явлений, спутники позволяют получать информацию о&nbsp;степени разрушения после стихийных бедствий, обеспечивать картографическую информацию, показывая места возникновения
        бедствий и&nbsp;помогая в&nbsp;организации помощи пострадавшим. Тепловые инфракрасные сканеры на&nbsp;их&nbsp;борту могут показать потенциальные источники пожара и&nbsp;вероятное направление
        ветра, который может распространить огонь. Немаловажным при обеспечении безопасности и&nbsp;помощи в&nbsp;ЧС является возможность использования спутников для связи и&nbsp;управления в&nbsp;кризисных
        ситуациях, налаживая связь в&nbsp;удаленных или разрушенных районах.
      </p>
      <p class="text-wrapper">
        Например, после катастрофы в&nbsp;Турции в&nbsp;2023 году космические агентства предоставили более 350 различных изображений пострадавших районов, которые использовались для создания карт
        повреждений, оценки опасности и&nbsp;управления действиями по&nbsp;оказанию помощи, спасению и&nbsp;эвакуации людей. Анализ на&nbsp;основе спутниковых наблюдений показал, что на&nbsp;территории
        потенциального риска проживает почти 4&nbsp;млн человек, затронутых последствиями землетрясений, подтверждено повреждению более 4&nbsp;тыс. зданий и&nbsp;72&nbsp;км различных дорог, а&nbsp;также
        были сделаны негативные прогнозы по&nbsp;изменению в&nbsp;географии и&nbsp;природной среде региона, такие как сдвиги почвы, оседание грунта, изменение водных объектов.
      </p>
    </div>
    <!--    END Section 3-->

    <div class="earth-footer">
      <img src="~@/assets/img/earth_2.webp" alt="">
    </div>

    <timeline :menu="menu" v-transfer-dom></timeline>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

.block-chapter {
  position: relative;
  padding: 0 1rem 65vh;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background-image: linear-gradient(180deg, var(--darkColor) 30%, transparent 40%);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
    background-image: linear-gradient(180deg, transparent 20%, rgba(19, 51, 105, 0.2) 70%, rgba(10, 92, 154, 0.3) 80%, rgba(#27a6ff, .45) 100%);
  }

  .video {
    z-index: -1;
  }
}

.earth-footer {
  position: absolute;
  bottom: 0;
  left: -1rem;
  right: -1rem;

  img {
    width: 100%;
    display: block;
  }
}

video {
  z-index: 2;
  filter: saturate(0) contrast(.7) brightness(.7);
}

.block-photo {
  margin-top: -5rem;
  margin-bottom: -5rem;
  z-index: -1;
  //opacity: .6;

  @media (max-width: $tablet) {
    margin-top: -8rem;
  }

  @media (max-width: $mobile) {
    margin-top: -5rem;
  }
}
</style>
