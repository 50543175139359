<script>
import {defineComponent} from 'vue'
import StippleImg from "@/components/effects/stippleImg.vue";

export default defineComponent({
  name: "blockquotePhoto",
  components: {StippleImg},
  props: {
    img: null,
    invertColor: false,
  },
  data() {
    return {
      isStippleImg: false,
    }
  },
  methods: {
    showStippleImg() {
      if (window.innerWidth <= 960) {
        this.isStippleImg = false;
      }
    }
  },
  mounted() {
    this.showStippleImg();
  }
})
</script>

<template>
  <div class="blockquote__photo" v-if="img">
    <stipple-img
        v-if="isStippleImg"
        :invertColor="invertColor"
        :imgOriginal="img"
    ></stipple-img>
    <img :src='require(`@/${img}`)' alt="" v-else >
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

.blockquote__photo {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 13;
  margin-bottom: -5rem;
  display: flex;
  flex-direction: column;
  //overflow: hidden;

  @media (max-width: $mobile) {
    width: 100vw;
    margin-left: -1rem;
  }
}

img {
  width: 100%;
  height: auto;

  @media (max-width: $mobile) {
    width: 140%;
    margin: 0 -20%;
  }
}
</style>
