<script>
import {defineComponent} from 'vue'
import MenuComponent from "@/components/layouts/menuComponent.vue";

export default defineComponent({
  name: "headerComponent",
  components: {MenuComponent},
  data() {
    return {
      isActive: false,
    }
  }
})
</script>

<template>
  <header>
    <div class="header-wrapper">
      <div class="logotype-header">
        <img src="~@/assets/img/Repost-logo__white.svg" alt="" class="logo">
        <img src="~@/assets/img/Repost-symbol__white.svg" alt="" class="symbol">

        <div class="about-block">
          <span> Сетевое аналитическое СМИ</span>
          <a href="https://repost.press" target="_blank">repost.press</a>
        </div>
      </div>
      <div class="btn-menu" :class="{'active': isActive}" @click="isActive = !isActive"></div>
    </div>

    <menu-component
        :class="{'active': isActive}"
        @hiddenMenu="isActive = !isActive"
    ></menu-component>
  </header>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/variables";
@import "~@/assets/style/utils/sizes";

header {
  position: relative;
  z-index: 2;

  &.show .header-wrapper {
    top: 0;
  }
}

.header-wrapper {
  position: fixed;
  display: flex;
  padding: 1rem 1.5rem 4rem 1rem;
  justify-content: space-between;
  align-items: center;
  top: -100%;
  left: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  transition: 2s top;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1 ;
    @media (max-width: $mobile) {
      backdrop-filter: blur(20px);
      mask-image: linear-gradient(180deg, black 30%, transparent);
    }
  }
}

.logotype-header {
  $width: calc(1rem + 1.3vw);
  height: $width;
  width: $width;
  position: relative;
  pointer-events: auto;
  cursor: pointer;

  img {
    position: absolute;
    height: 80%;
    width: auto;
    transition: .5s opacity, .4s left;
  }

  .logo {
    left: -5.47vw;
    top: 0;
    opacity: 0;
  }

  .symbol {
    left: 0;
    opacity: 1;
  }

  .about-block {
    opacity: 0;
    position: absolute;
    font-size: calc(.8rem + .1vw);
    top: -1.5rem;
    left: -1rem;
    z-index: -1;
    padding: 4vw 1vw 1vw;
    line-height: 1;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    pointer-events: none;
    transition: .8s opacity;


    @media (max-width: $mobile) {
      padding: 12vw 2.9vw 1vw;
    }

    &::before {
      content: '';
      position: absolute;
      left: -10vw;
      right: 100%;
      bottom: 100%;
      top: -10vw;
      z-index: -1;
      background: var(--orangeColor);
      filter: blur(100px);
      transition: .8s right, .8s bottom;
    }

    a {
      color: var(--goldColor);
      text-decoration: none;
      padding-top: .5vw;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }

    span, a {
      position: relative;
      left: -2vw;
      transition: .9s left;
    }
  }


  &:hover {

    img {
      transition: 1.5s opacity, .4s left;
    }

    .logo {
      left: 0;
      opacity: 1;
    }

    .symbol {
      left: 5.47vw;
      opacity: 0;
    }

    .about-block {
      opacity: 1;
      pointer-events: auto;

      &::before {
        right: 0;
        bottom: 0;
        filter: blur(100px);

        @media (max-width: $mobile) {
          filter: blur(30px);
          right: -5vw;
          bottom: -4vw;
        }
      }
    }

    span, a {
      left: 0;
    }
  }

  @media (max-width: $mobile) {
    $width: calc(1rem + 5vw);
    height: $width;
  }
}

.btn-menu {
  height: calc(1rem + 1.3vw);
  width: calc(1rem + 1.3vw);
  position: relative;
  cursor: pointer;
  pointer-events: auto;
  --paddingBorder: .7vw;

  @media (max-width: $mobile) {
    --paddingBorder: 1.7vw;
    height: calc(1rem + 4vw);
    width: calc(1rem + 5vw);
  }

  &::before {
    content: '';
    height: 2px;
    position: absolute;
    top: var(--paddingBorder);
    left: 50%;
    width: 100%;
    background: white;
    margin-left: -50%;
    transition: transform .3s, top .3s, background-color .3s;
  }

  &::after {
    content: '';
    height: 2px;
    position: absolute;
    bottom: var(--paddingBorder);
    left: 50%;
    width: 100%;
    background: white;
    margin-left: -50%;
    transition: transform .3s, top .3s, background-color .3s;
  }

  &:hover {
    &::before,
    &::after {
      background: var(--goldColor);
    }
  }

  &.active {
    &::before {
      top: 50%;
      transform: rotate(45deg);
    }

    &::after {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
}


</style>
