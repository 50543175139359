<script>
import {defineComponent} from 'vue'
import p5 from 'p5';

export default defineComponent({
  name: "stippleImg",
  props: {
    imgOriginal: null,
    invertColor: false,
  },
  data() {
    return {
      isImg: false,
      img: null,
      tiles: 0,
      directionBlock: 'bottom',
      isNoLoop: false,
    }
  },
  methods: {
    initP5() {
      const positionImg = this.$refs.img.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const reserveSize = 300;

      // Загружаем скрипт в нужный момент
      if (!this.isImg && (positionImg <= windowHeight + reserveSize && positionImg >= -2 * reserveSize)) {
        new p5(this.drawCanvas, this.$refs.img);
        this.isImg = true;
      }
    },
    drawCanvas(p5) {
      //Загружкаем картинку
      p5.preload = _ => {
        this.preload(p5);
      }

      //
      p5.setup = _ => {
        this.setup(p5);

        window.onscroll = () => {
          this.draw(p5)
        }
      }

    },
    preload(p5) {
      let img = require(`@/${this.imgOriginal}`);
      this.img = p5.loadImage(img);
    },
    setup(p5) {
      const sizeImg = this.getSizeImg(this.img);
      p5.createCanvas(sizeImg.width, sizeImg.height).elt.getContext('2d', {willReadFrequently: true});
      this.img.resize(sizeImg.width, sizeImg.height);
    },
    draw(p5) {
      if (!this.isNoLoop) {
        this.tiles = this.$refs.img.getBoundingClientRect().top < window.innerHeight - 400 ? p5.float((window.innerHeight - this.$refs.img.getBoundingClientRect().top - 400) / 3) : 0;
        this.stylingPhoto(p5, this.tiles);
      }
    },
    stylingPhoto(p5, tiles = 200) {
      if(!this.isNoLoop) {
        p5.clear();
        const sizeImg = this.getSizeImg(this.img);
        if (this.tiles < 200 && window.innerWidth > 1024 && window.innerHeight > 800) {
          const tileSize = p5.float(p5.width / tiles);
          for (let x = 0; x < tiles; x++) {
            for (let y = 0; y < tiles; y++) {
              const c = p5.color(this.img.get(p5.int(x * tileSize), p5.int(y * tileSize)));

              const size = p5.float(p5.map(p5.brightness(c), 100, 0, 6, 0));

              size >= 4 ? p5.fill(246, 240, 226) : size >= 3 ? p5.fill(206, 200, 186) : p5.fill(166, 160, 146);


              if (size >= 2) {
                p5.noStroke();
                p5.ellipse(x * tileSize + ((sizeImg.width - this.img.width) / 2), y * tileSize, size, size);
              }
            }
          }
        } else {
          p5.image(this.img, 0, 0);
          this.isNoLoop = true;
        }
      }
    },
    getSizeImg(img) {
      // Прапорционально уменьшаем картинку под размер блока
      const ratio = img.height / img.width;
      const count = this.$refs.img.getBoundingClientRect().width < 600 ? 1.4 : 1;
      const sizeImg = {
        height: this.$refs.img.getBoundingClientRect().width * count * ratio,
        width: this.$refs.img.getBoundingClientRect().width * count,
      }
      return sizeImg;
    },
    setSizeBlockImg() {
      // Сразу, при загрузке страницы, задаем размеры картинки блоку, чтобы страница не дергалась при скролле снизу вверх
      let newImg = new Image();
      newImg.src = require(`@/${this.imgOriginal}`);
      newImg.onload = () => {
        const ratio = newImg.height / newImg.width;
        this.$refs.img.style.height = `${this.$refs.img.getBoundingClientRect().width * ratio}px`;
      }
    },
    getDirectionScroll() {
      let oldPosBlock = this.posBlock;
      this.posBlock = this.$refs.img.getBoundingClientRect().top;
      return this.posBlock > oldPosBlock ? 'top' : 'bottom';
    }
  },
  mounted() {
    window.addEventListener('scroll', this.initP5);
  }
})
</script>

<template>
  <div ref="img">
  </div>
</template>

<style lang="scss" scoped>
  div {
    width: 100%;
  }
</style>
