<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "videoComponent",
  props: {
    src: null,
    poster: null,
    type: {
      type: String,
      default: 'webm',
    },
  },
  methods: {
    playVideo() {
      Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get: function () {
          return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        }
      });

      document.body.ontouchstart = () => {
        if (!this.$refs.video.playing) this.$refs.video.play();
      }
    },
  },
  mounted() {
    // this.playVideo();
  }
})
</script>

<template>
  <div class="video">
    <video v-if="src" no-controls autoplay muted playsinline :poster='require(`@/${poster}`)' ref="video">
      <source :src='require(`@/${src}`)' :type='`video/${type}`'/>
    </video>
  </div>
</template>

<style scoped lang="scss">
.video {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100vw;
  z-index: -2;

  video {
    width: 100vw;
    opacity: .2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(0deg, var(--darkColor) 10%, transparent 100%);
    z-index: 1000;
  }
}
</style>
