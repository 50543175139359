<script>
import {defineComponent} from 'vue'
import titleSection from "@/components/blocks/titleSection.vue";
import BlockquoteComponent from "@/components/blocks/blockquoteComponent.vue";
import Timeline from "@/components/layouts/timeline/timelineWrapper.vue";
import Stars from "@/components/effects/stars.vue";
import {infinityScrollPage} from "@/assets/js/infinityScroll";
import BlockPhoto from "@/components/blocks/blockPhoto.vue";
import VideoComponent from "@/components/blocks/videoComponent.vue";

export default defineComponent({
  name: "firstCharter",
  components: {
    VideoComponent,
    BlockPhoto,
    Stars,
    Timeline,
    BlockquoteComponent,
    titleSection,
  },
  data() {
    return {
      isLoading: true,
      menu: [
        {
          link: 'kholodnaya-voyna',
          title: 'Холодная война',
        },
        {
          link: 'raketostroitelnaya-promyshlennost',
          title: 'Ракетостроительная промышленность',
        },
        {
          link: 'pervyy-chelovek-v-kosmose',
          title: 'Первый человек в космосе',
        },
        {
          link: 'apollo',
          title: 'Apollo',
        },
        {
          link: 'rukopozhatiye-v-kosmose',
          title: '«Рукопожатие в космосе»',
        },
      ],
      x: 70,
      oldTopCoordinateEl: 0,
    }
  },
  methods: {},
  mounted() {
    infinityScrollPage(this.$el);
  }
})
</script>

<template>
  <div class="block-chapter">
    <video-component src="assets/video/chapter_1.webm" poster="assets/video/chapter_1.webp"></video-component>
    <title-section
        src="pervyye-kosmicheskiye-derzhavy"
    >
      <template v-slot:title>Первые космические державы</template>
      <template v-slot:subheading>В&nbsp;ХХ&nbsp;веке человечество впервые вступило на&nbsp;порог Вселенной&nbsp;&mdash; вышло в&nbsp;космическое пространство. Первый пилотируемый полёт на&nbsp;орбиту
        стал одним из&nbsp;величайших научных достижений в&nbsp;истории человечества, закрепившим за&nbsp;Советским Союзом роль мирового лидера в&nbsp;развитии космонавтики. С&nbsp;тех пор идея
        космического превосходства стала важной составляющей национальной идеологии сильнейших держав, а&nbsp;космические успехи&nbsp;&mdash; показателем научно-технического потенциала и&nbsp;военной
        мощи государств.
      </template>
    </title-section>

    <!--    Section 1-->
    <div class="container">
      <h3 class="text-wrapper" id="kholodnaya-voyna">Холодная война</h3>
      <p class="text-wrapper">
        Мощный импульс к&nbsp;развитию космической отрасли дала Вторая мировая война, по&nbsp;итогам которой в&nbsp;мире появились две сверхдержавы&nbsp;&mdash; СССР и&nbsp;США&nbsp;&mdash; вступившие
        в&nbsp;эпоху холодной войны. К&nbsp;тому времени США обладали большим флотом стратегических бомбардировщиков, размещённых на&nbsp;авиабазах по&nbsp;всему миру. В&nbsp;качестве ответной меры
        советским руководством было
        принято решение развивать ракетную технику.
      </p>
      <p class="text-wrapper">
        В&nbsp;мае 1946 года Совет министров СССР принял постановление &laquo;Вопросы реактивного вооружения&raquo;, которое положило начало практически всем существующим по&nbsp;сей день
        производственным и&nbsp;научно-исследовательским центрам, объявлялось о&nbsp;развитии современной ракетостроительной промышленности и&nbsp;создании Особого конструкторского бюро &#8470;&nbsp;1&nbsp;НИИ-88&nbsp;во
        главе с&nbsp;Сергеем Королевым.
      </p>
    </div>
    <blockquote-component>
      <template v-slot:blockquote>
        Считать работы по&nbsp;развитию реактивной техники <b>важнейшей государственной задачей</b> и&nbsp;обязать все министерства и&nbsp;организации выполнять задания по&nbsp;реактивной технике как
        <b>первоочередные</b>.
      </template>
      <template v-slot:sign>
        <a href="https://istmat.org/files/uploads/64379/garf_f.p-5446._op.3ac._d._23_voprosi_reaktivnogo_vooruzhenia.pdf" class="link" target="_blank">Постановление Совета Министров СССР от
          13.05.1946
          № 1017-419сс «Вопросы реактивного вооружения»</a>
      </template>
    </blockquote-component>
    <div class="container">
      <p class="text-wrapper">
        Конструкторские коллективы смежных предприятий возглавили крупные специалисты в&nbsp;области ракетной промышленности, получившие опыт при изучении техники в&nbsp;Германии (в&nbsp;том числе
        ракеты &laquo;Фау-2&raquo;): В.П. Глушко, Н.А. Пилюгин, М.С. Рязанский, В.И. Кузнецов, В.П. Бармин, А.М. Исаев.
      </p>
      <p class="text-wrapper">
        Результатом работы стало создание баллистической ракеты Р-1 и&nbsp;стратегических ракет Р-5М, Р-12.
      </p>
    </div>
    <!--    END Section 1-->

    <!--    Section 2-->
    <div class="container">
      <h3 class="text-wrapper" id="raketostroitelnaya-promyshlennost">Ракетостроительная промышленность</h3>
      <p class="text-wrapper">
        Началом эпохи освоения космоса можно считать запуск 4&nbsp;октября 1957 года первого искусственного спутника Земли&nbsp;&mdash; &laquo;Спутник-1&raquo;. Спутник летал 92&nbsp;дня, до&nbsp;4&nbsp;января
        1958&nbsp;года, совершив 1440 оборотов вокруг Земли (около 60&nbsp;миллионов километров), а&nbsp;его радиопередатчики работали в&nbsp;течение двух недель после старта.
      </p>
    </div>
    <blockquote-component img="assets/img/korolev.webp">
      <template v-slot:blockquote>
        Пророческие слова Константина Эдуардовича Циолковского о&nbsp;том, что человечество вечно не&nbsp;останется на&nbsp;Земле, сбылись! Сегодня на&nbsp;околоземную орбиту выведен первый в&nbsp;мире
        искусственный спутник. С&nbsp;выводом его начался штурм космоса. И&nbsp;первой страной, проложившей дорогу в&nbsp;космическое пространство, явилась наша страна&nbsp;&mdash; страна Советов!
      </template>
      <template v-slot:sign>
        <a href="https://ru.wikipedia.org/wiki/%D0%9A%D0%BE%D1%80%D0%BE%D0%BB%D1%91%D0%B2,_%D0%A1%D0%B5%D1%80%D0%B3%D0%B5%D0%B9_%D0%9F%D0%B0%D0%B2%D0%BB%D0%BE%D0%B2%D0%B8%D1%87" class="link"
           target="_blank">Сергей Королев</a> &mdash; советский учёный, конструктор ракетно-космических систем
      </template>
    </blockquote-component>
    <div class="container">
      <p class="text-wrapper">
        Затем последовали второй и&nbsp;третий спутники, в&nbsp;том числе с&nbsp;животными. В&nbsp;1959 году начались изучения естественного спутника Земли&nbsp;&mdash; Луны. К&nbsp;Луне
        последовательно отправилось три космических аппарата, которым удалось не&nbsp;только сфотографировать ее&nbsp;обратную сторону, но&nbsp;и&nbsp;оставить свой след на&nbsp;ее&nbsp;поверхности.
      </p>
      <p class="text-wrapper">
        В&nbsp;феврале 1961 года впервые космический аппарат отправился к&nbsp;Венере, а&nbsp;в&nbsp;1970 году советская станция &laquo;Венера-7&raquo; совершила успешную посадку.
      </p>
      <p class="text-wrapper">
        Успешные советские запуски разрушили миф о&nbsp;технологической мощи Америки: 6&nbsp;декабря 1957 года на&nbsp;мысе Канаверал должен был состояться запуск первого американского спутника, но&nbsp;ракета
        смогла подняться лишь на&nbsp;1,2&nbsp;м, после чего взорвалась. Далее последовали и&nbsp;другие космические неудачи США, что в&nbsp;очередной раз доказало превосходство СССР.
      </p>
    </div>
    <!--    END Section 2-->

    <!--    Section 3-->
    <div class="container">
      <h3 class="text-wrapper" id="pervyy-chelovek-v-kosmose">Первый человек в&nbsp;космосе</h3>
      <p class="text-wrapper">
        12&nbsp;апреля 1961 года состоялся исторический полет Юрия Гагарина в&nbsp;космос.
      </p>
      <p class="text-wrapper">
        Космический корабль &laquo;Восток&raquo; взлетел с&nbsp;советского космодрома &laquo;Байконур&raquo; и&nbsp;спустя 1&nbsp;час 48&nbsp;минут, совершив виток вокруг Земли, приземлился в&nbsp;Саратовской
        области. Первому космонавту Планеты было присвоено звание Героя Советского Союза.
      </p>
    </div>
    <blockquote-component img="assets/img/gagarin.webp">
      <template v-slot:blockquote>
        Это не&nbsp;моя личная слава. Разве я&nbsp;бы мог проникнуть в&nbsp;космос, будучи одиночкой? Это слава нашего народа.
      </template>
      <template v-slot:sign>
        <a href="https://ru.wikipedia.org/wiki/%D0%93%D0%B0%D0%B3%D0%B0%D1%80%D0%B8%D0%BD,_%D0%AE%D1%80%D0%B8%D0%B9_%D0%90%D0%BB%D0%B5%D0%BA%D1%81%D0%B5%D0%B5%D0%B2%D0%B8%D1%87" class="link"
           target="_blank">Юрий Гагарин</a> &mdash; первый человек в мировой истории, совершивший полёт в космическое пространство
      </template>
    </blockquote-component>
    <!--    END Section 3-->

    <!--    Section 4-->
    <div class="container">
      <h3 class="text-wrapper" id="apollo">Apollo</h3>
      <p class="text-wrapper">
        Космическая гонка набирала обороты&nbsp;&mdash; американцы, желая обогнать Советский Союз, стали развивать Лунную программу &laquo;Apollo&raquo;, целью которой было осуществить первую в&nbsp;мире
        пилотируемую высадку на&nbsp;Луну. В&nbsp;сентябре 1962 года Джон Кеннеди обратился к&nbsp;нации с&nbsp;легендарной речью: &laquo;We&nbsp;choose to&nbsp;go&nbsp;to&nbsp;the moon&raquo;.
      </p>
      <p class="text-wrapper">
        20&nbsp;июля 1969 года &laquo;Аполлон-11&raquo; совершил успешную посадку на&nbsp;поверхность небесного тела Луны, а&nbsp;первым человеком, ступившим на&nbsp;Луну, стал Нил Армстронг.
      </p>
    </div>
    <blockquote-component img="assets/img/armstrong.webp">
      <template v-slot:blockquote>
        Это один маленький шаг для человека, но&nbsp;гигантский скачок для всего человечества.
      </template>
      <template v-slot:sign>
        <a href="https://ru.wikipedia.org/wiki/%D0%90%D1%80%D0%BC%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%B3,_%D0%9D%D0%B8%D0%BB" class="link"
           target="_blank">Нил Армстронг</a> &mdash; первый человек, ступивший на&nbsp;Луну 20&nbsp;июля 1969 года в&nbsp;ходе лунной экспедиции корабля &laquo;Аполлон-11&raquo;
      </template>
    </blockquote-component>
    <div class="container">
      <p class="text-wrapper">
        Всего по&nbsp;программе &laquo;Аполлон&raquo; были совершены 6&nbsp;успешных высадок астронавтов на&nbsp;Луну, последняя&nbsp;&mdash; в&nbsp;1972&nbsp;году.
      </p>
    </div>
    <!--    END Section 4-->

    <!--    Section 5-->
    <div class="container">
      <h3 class="text-wrapper" id="rukopozhatiye-v-kosmose">&laquo;Рукопожатие в&nbsp;космосе&raquo;</h3>
      <p class="text-wrapper">
        Разрядка международной напряжённости 70-х годов коснулась и&nbsp;космической отрасли. В&nbsp;мае 1970 года первый человек на&nbsp;Луне Нил Армстронг приехал в&nbsp;Советский Союз. Он&nbsp;подарил
        Председателю Совета министров СССР Алексею Косыгину ёмкость с&nbsp;образцами лунного грунта и&nbsp;небольшой флаг Советского Союза, побывавший на&nbsp;Луне.
      </p>
      <p class="text-wrapper">
        4&nbsp;мая 1972 года председателем Совета министров СССР Алексеем Косыгиным и&nbsp;президентом США Ричардом Никсоном было подписано &laquo;Соглашение о&nbsp;сотрудничестве в&nbsp;исследовании
        и&nbsp;использовании космического пространства в&nbsp;мирных целях&raquo;. Результатом сотрудничества стал экспериментальный пилотируемый полет советского космического корабля &laquo;Союз-19&raquo;
        и&nbsp;американского космического корабля &laquo;Аполлон&raquo; 15&nbsp;июля 1975&nbsp;года, в&nbsp;ходе которого произошла стыковка кораблей двух стран и&nbsp;взаимный переход членов
        экипажей.
      </p>

      <p class="text-wrapper description-photo">
        &laquo;Союз&raquo;-&laquo;Аполлон&raquo; стал символом дипломатического прорыва двух космических держав, мировое сообщество затаилось в&nbsp;ожидании новых совместных наработок СССР и&nbsp;США, но&nbsp;наступил новый виток холодной войны.
      </p>

      <block-photo img="assets/img/sa.webp">
      </block-photo>

    </div>
    <!--    END Section 5-->


    <timeline :menu="menu" v-transfer-dom></timeline>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

.block-chapter {
  position: relative;
  padding-bottom: 8vw;
  margin-bottom: -21.5vw;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -2;
    background: linear-gradient(180deg, var(--darkColor) 30%, transparent 40%);
  }

  @media (max-width: $desktopMin) {
    margin-bottom: -40vw;
  }


  @media (max-width: $mobile) {
    margin-bottom: -60vw;
  }
}

.block-photo {
  margin-top: -9rem;

  @media (max-width: $tablet) {
    margin-top: -8rem;
  }

  @media (max-width: $mobile) {
    margin-top: -5rem;
  }
}

.description-photo {
  @media (max-width: $tablet) {
    padding: 0 2rem;
  }
}
</style>
