<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "earthComponent",
  props: {
    isCover: {
      type: Boolean,
      default: true,
    }
  }
})
</script>

<template>
  <div class="earth-wrapper" :class="{'appearance': isCover}">
    <div class="earth">
      <img src="~@/assets/img/earth.webp" alt="">
    </div>
    <div class="moon"><img src="~@/assets/img/Repost-symbol__white.svg" alt=""></div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";
@import "~@/assets/style/utils/mixins";

.earth {
  position: relative;
  width: var(--sizeEarth);
  height: var(--sizeEarth);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: var(--sizeEarth);
    height: var(--sizeEarth);
    background-image: linear-gradient(90deg, rgba(#3A589A, .2), rgba(#3A589A, .2)),
    linear-gradient(-90deg, transparent 35%, rgba(black, .7) 75%),
    linear-gradient(-180deg, transparent 35%, rgba(black, .7) 75%);
    background-size: 100%;
    border-radius: 100%;
    overflow: hidden;
    box-shadow: .5vw .3vw 1.25vw -.25vw rgba(#6cc0f0, 1),
    0 0 3.5vw 2.5vw rgba(#2662ad, .4),
    0 0 10vw 1.5vw rgba(#4562e3, .2),
    inset -2vw -2vw 1vw .5vw rgba(#8ac9f2, .2),
    inset -1vw -.5vw .5vw -.25vw rgba(#8ad8f2, .4),
    inset -.4vw -.25vw .2vw -.25vw rgba(#e6f3f8, 1);
  }

  img {
    width: 100%;
    height: auto;
    z-index: -1;
    position: relative;
  }
}

.moon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: 20s rotate;
  @include no-cpu;

  img {
    --sizeMoon: calc(1rem + 1vw);
    width: var(--sizeMoon);
    height: var(--sizeMoon);
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 5px 5px 15px 0 rgba(white, .6), 5px 10px 30px 0 rgba(white, .1), inset 0 0 0 2px white;
  }
}

.earth-wrapper {
  position: relative;
  --sizeEarth: calc(1rem + 20vw);

  @media (max-width: $mobile) {
    --sizeEarth: calc(2rem + 30vw);
  }
}

.appearance {
  @include no-cpu;
  animation: 6s scaleEarth;

  .earth::after {
    @include no-cpu;
    animation: 20s rotate 2s;
  }
}

@keyframes scaleEarth {
  from {
    opacity: 0;
    transform: scale(4) rotate(15deg);
  }

  to {
    opacity: 1;
    transform: scale(1) rotate(0deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}
</style>
