<script>
import {defineComponent} from 'vue'
import titleSection from "@/components/blocks/titleSection.vue";
import {infinityScrollPage} from "@/assets/js/infinityScroll";
import Timeline from "@/components/layouts/timeline/timelineWrapper.vue";
import Stars from "@/components/effects/stars.vue";
import VideoComponent from "@/components/blocks/videoComponent.vue";
import BlockPhoto from "@/components/blocks/blockPhoto.vue";

export default defineComponent({
  name: "fourthChapter",
  components: {BlockPhoto, VideoComponent, Stars, Timeline, titleSection},
  data() {
    return {
      menu: [
        {
          link: 'chastnyye-kompanii',
          title: 'Частные компании и их вклад в реализацию государственной политики',
        },
        {
          link: 'novyye-strany',
          title: 'Новые страны вступают в космическую гонку',
        },
      ]
    }
  },
  mounted() {
    infinityScrollPage(this.$el)
  }
})
</script>

<template>
  <div class="block-chapter">
    <video-component src="assets/video/chapter_4.webm" poster="assets/video/chapter_4.webp"></video-component>
    <title-section :translate="false" src="vozobnovleniye-kosmicheskoy-gonki">
      <template v-slot:title>Возобновление космической гонки</template>
    </title-section>

    <!--    Section 1-->
    <div class="container">
      <h3 class="text-wrapper" id="chastnyye-kompanii">Частные компании и&nbsp;их&nbsp;вклад в&nbsp;реализацию государственной политики</h3>
      <p class="text-wrapper subheading">
        В&nbsp;настоящее время для содействия выполнению боевых задач к&nbsp;вооруженным силам космических держав подключаются и&nbsp;частные организации. Несмотря на&nbsp;то, что вовлечение
        коммерческих корпораций в&nbsp;конфликты недопустимо, сами компании оказывают различные услуги военным или государственным структурам как в&nbsp;личных интересах, так и&nbsp;в&nbsp;интересах
        страны.
      </p>
      <block-photo img="assets/img/spacex.webp"></block-photo>
<!--      <h4 class="text-wrapper">SpaceX</h4>-->
      <p class="text-wrapper">
        Известно, что компания SpaceX, основанная миллиардером Илоном Маском, вовлечена в&nbsp;российско-украинский конфликт: в&nbsp;2022&nbsp;году, во&nbsp;время проводимой СВО по&nbsp;защите жителей
        Донбасса, американская компания предоставила более 20&nbsp;000 спутников Starlink, которые использовались в&nbsp;качестве средств обеспечения коммуникации между подразделениями Вооруженных сил
        Украины.
      </p>
      <block-photo img="assets/img/ng.webp"></block-photo>
<!--      <h4 class="text-wrapper">Lockheed Martin и&nbsp;Northrop Grumman</h4>-->
      <p class="text-wrapper">
        Частные корпорации в&nbsp;оборонном секторе США&nbsp;&mdash; Lockheed Martin и&nbsp;Northrop Grumman&nbsp;&mdash; заявляют, что активно сотрудничают с&nbsp;американскими агентствами и&nbsp;военными
        ведомствами, в&nbsp;том числе и&nbsp;в&nbsp;сфере обеспечения безопасности в&nbsp;космическом пространстве. Так, между ними и&nbsp;Агентством космического развития был заключен контракт на&nbsp;$1,5
        млрд на&nbsp;производство 72&nbsp;спутников, которые будут использоваться в&nbsp;целях повышения скорости спутниковой связи, а&nbsp;также разведки, обнаружения и&nbsp;наблюдения за&nbsp;пусками
        и&nbsp;движениями межконтинентальных баллистических гиперзвуковых ракет.
      </p>
      <block-photo img="assets/img/raytheon.webp"></block-photo>
<!--      <h4 class="text-wrapper">Raytheon</h4>-->
      <p class="text-wrapper">
        Применение систем перехвата ракет является одним из&nbsp;ключевых инструментов для обеспечения безопасности в&nbsp;космическом пространстве. Компания Raytheon, которая также тесно сотрудничает
        с&nbsp;государственными структурами США, разработала космический аппарат, способный с&nbsp;помощью датчиков вычислять направления полета баллистической ракеты и&nbsp;нейтрализовать&nbsp;ее.
        Кроме того, компанией была разработана система FORGE MDPAF, способная обрабатывать огромное количество спутниковых данных постоянного инфракрасного излучения. Данная система генерирует
        оповещения и&nbsp;отчеты об&nbsp;угрозах для вооруженных соединений и&nbsp;служб быстрого реагирования.
      </p>
      <block-photo img="assets/img/planet.webp"></block-photo>
<!--      <h4 class="text-wrapper">Planet</h4>-->
      <p class="text-wrapper">
        К&nbsp;частным компаниям также нередко обращаются различные исследовательские институты и&nbsp;аналитические центры, сотрудничающие с&nbsp;правительствами стран. После испытаний запусков
        межконтинентальных баллистических ракет КНДР в&nbsp;2017 и&nbsp;2019 годах компания Planet, по&nbsp;заказу аналитического мозгового центра Великобритании&nbsp;&mdash; Royal United Services
        Institute, с&nbsp;помощью систем спутникового наблюдения обнаружила потоки грузовых кораблей, перевозящих топливо в&nbsp;Северную Корею, которая пыталась обойти торговое эмбарго.
      </p>
      <h4 class="text-wrapper">Искусственный интеллект</h4>
      <p class="text-wrapper">
        Сегодня большинство коммерческих спутников оснащены инструментами для наблюдения, сбора и&nbsp;обработки полученных данных. С&nbsp;развитием технологий к&nbsp;этому процессу подключился и&nbsp;искусственный
        интеллект, который с&nbsp;помощью машинного и&nbsp;глубокого обучения дает возможность космическому аппарату автономно провести желаемый анализ в&nbsp;зависимости от&nbsp;изначально
        поставленной задачи&nbsp;&mdash; от&nbsp;мониторингов экономических показателей и&nbsp;экологической обстановки до&nbsp;обнаружения преступников и&nbsp;вражеских группировок&nbsp;&mdash; и&nbsp;произвести
        необходимые расчеты для эффективного прогнозирования. Такими технологиями пользуются как частные компании, например, BlackSky, так и&nbsp;государственные корпорации, среди которых&nbsp;&mdash;
        NASA.
      </p>
    </div>
    <!--    END Section 1-->


    <!--    Section 2-->
    <div class="container">
      <h3 class="text-wrapper" id="novyye-strany">Новые страны вступают в&nbsp;космическую гонку</h3>
      <p class="text-wrapper subheading">
        Государства на&nbsp;протяжении десятилетий конкурировали в&nbsp;космосе. Сегодня к&nbsp;гонке трех космических держав&nbsp;&mdash; России, Китая и&nbsp;США&nbsp;&mdash; присоединяются все
        новые страны, которые демонстрируют миру передовые космические разработки.
      </p>
      <block-photo img="assets/img/france.webp"></block-photo>
      <h4 class="text-wrapper">Франция</h4>
      <p class="text-wrapper">
        Франция занимает первое место в&nbsp;Европе по&nbsp;количеству космических разработок, а&nbsp;французский Национальный центр космических исследований (CNES) является третьим старейшим
        космическим агентством в&nbsp;мире. Во&nbsp;Франции была создана первая космическая ракета, которая была сконструирована за&nbsp;пределами США и&nbsp;России, впоследствии она получила название
        &laquo;Диамант&raquo;.
      </p>
      <p class="text-wrapper">
        Именно Франция стала инициатором и&nbsp;разработчиком ракеты-носителя &laquo;Ариан&raquo;, которая до&nbsp;сих пор стоит на&nbsp;вооружении Европейского союза. В&nbsp;настоящий момент на&nbsp;базе
        французской компании Arianespace дорабатывается и&nbsp;тестируется новая модель ракеты-носителя &laquo;Ariane-6&raquo;, которая должна прийти на&nbsp;смену устаревающей &laquo;Ariane-5&raquo;.
        Первый запуск &laquo;Ariane-6&raquo; планируется в&nbsp;начале 2024 года с&nbsp;несколькими малыми спутниками, включая поддерживаемый NASA кубсат Cubesat Radio Interferometry Experiment или
        CURIE.
      </p>
      <p class="text-wrapper">
        К&nbsp;тому&nbsp;же французы активно инвестируют в&nbsp;национальный космический сектор. Так, премьер-министр Франции Элизабет Борн в&nbsp;2022 году заявила о&nbsp;выделении &euro;&nbsp;9&nbsp;млрд
        на&nbsp;развитие космической промышленности и&nbsp;научных исследований в&nbsp;данной отрасли.
      </p>
      <block-photo img="assets/img/india.webp" ></block-photo>
      <h4 class="text-wrapper">Индия</h4>
      <p class="text-wrapper">
        В&nbsp;1980 году Индия стала восьмой космической державой, осуществив тестовый запуск ракеты ASLV. Сегодня страна имеет один из&nbsp;крупнейших в&nbsp;мире флотов навигационных спутников и&nbsp;активно
        поощряет космические стартапы и&nbsp;разработки. В&nbsp;2020 году правительство Индии предоставило частным компаниям доступ к&nbsp;инфраструктуре Индийской организации космических исследований
        (ISRO). Как итог, в&nbsp;ноябре 2022 года ISRO провела успешный запуск ракеты-носителя Vikram-S, которую разработала частная компания Skyroot Aerospace.
      </p>
      <p class="text-wrapper">
        23&nbsp;августа 2023 года Индия стала первой страной в&nbsp;мире, успешно посадившей космический корабль вблизи южного полюса Луны в&nbsp;рамках третьей лунной миссии ISRO &laquo;Чандраян-3&raquo;.
      </p>
      <block-photo img="assets/img/japan.webp"></block-photo>
      <h4 class="text-wrapper">Япония</h4>
      <p class="text-wrapper">
        Япония начала освоение космоса через 12&nbsp;лет после успехов Советского Союза, став первой азиатской державой, вошедшей в&nbsp;&laquo;космический клуб&raquo; на&nbsp;два месяца раньше
        китайцев. В&nbsp;1970&nbsp;году, с&nbsp;пятого раза, японцам удалось осуществить запуск ракеты &laquo;Lambda-4S&raquo;, как итог, на&nbsp;орбите появился национальный спутник &laquo;Осуми&raquo;.
        Это событие послужило началом крупномасштабной &laquo;научной миссии&raquo; в&nbsp;космосе: японцы активно участвовали в&nbsp;строительстве МКС, создали оборудование для изучения солнечного
        ветра и&nbsp;космической радиации, первый космический телескоп и&nbsp;многое другое&nbsp;&mdash; до&nbsp;сих пор научные исследования являются сильной стороной японской космонавтики.
      </p>
      <p class="text-wrapper">
        В&nbsp;середине 1980-х годов руководство Японского космического агентства приняло решение о&nbsp;разработке тяжелой ракеты-носителя, базирующейся исключительно на&nbsp;японских технологиях.
        Так, в&nbsp;1994 году был совершен первый запуск ракеты H-II, а&nbsp;к&nbsp;1997 году было выполнено ещё пять успешных запусков. Позднее эти наработки легли в&nbsp;основу ракеты H2A 41F с&nbsp;государственным
        спутником оптической разведки, которая стала известна миру в&nbsp;2020&nbsp;году.
      </p>
      <p class="text-wrapper">
        На&nbsp;протяжении всей истории одним из&nbsp;прикладных направлений японской космонавтики являлась разведывательная деятельность. На&nbsp;околоземной орбите развернута и&nbsp;функционирует
        спутниковая группировка, задачей которой является наблюдение за&nbsp;Северной Кореей. Она появилась в&nbsp;космосе после того, как КНДР обзавелась собственной ядерной бомбой.
      </p>
      <p class="text-wrapper">
        На&nbsp;данном этапе Япония работает над несколькими программами в&nbsp;космической области, одной из&nbsp;которых является создание орбитальных солнечных панелей для передачи энергии из&nbsp;космоса
        на&nbsp;Землю. Данный ресурс потенциально неисчерпаем&nbsp;и, несмотря на&nbsp;высокую стоимость установки, делает такой способ добычи привлекательным для крупных корпораций. Проект
        реализуется частными японскими компаниями совместно с&nbsp;государственными структурами, его запуск прогнозируется в&nbsp;2025&nbsp;году.
      </p>
      <p class="text-wrapper">
        В&nbsp;октябре 2023 года Японское агентство аэрокосмических исследований объявило о&nbsp;старте флагманского космического проекта при поддержке компании Mitsubishi Heavy Industries (MHI). Его
        суть заключается в&nbsp;исследовании и&nbsp;разработке модели многоразовой ракеты следующего поколения, чья грузоподъёмность должна увеличиться, а&nbsp;стоимость снизиться, по&nbsp;сравнению с&nbsp;ракетой
        H3. На&nbsp;данном этапе предполагается использование ракеты для космических перевозок оборудования на&nbsp;лунную орбиту или поверхность Луны, но&nbsp;в&nbsp;перспективе возможно ее&nbsp;использование
        и&nbsp;для полетов людей в&nbsp;космос. Реализация проекта запланирована на&nbsp;2030&nbsp;год.
      </p>
      <block-photo img="assets/img/iran.webp"></block-photo>
      <h4 class="text-wrapper">Иран</h4>
      <p class="text-wrapper">
        Несмотря на&nbsp;продолжающиеся всеобъемлющие экономические санкции против Ирана и&nbsp;общемировую политическую блокаду, в&nbsp;стране продолжают активно развивать космический потенциал. В&nbsp;1998
        году Иран продемонстрировал миру первую собственную баллистическую ракету средней дальности &laquo;Шахаб-3&raquo;, позднее на&nbsp;ее&nbsp;основе пытались разработать &laquo;Шахаб-4&raquo;,
        который, по&nbsp;заявлениям властей, должен был стать прообразом спутникового носителя. Спустя десятилетие Иран обзавёлся собственным космодромом &laquo;Семнан&raquo; и&nbsp;вывел на&nbsp;орбиту
        первый иранский спутник &laquo;Омид&raquo;.
      </p>
      <p class="text-wrapper">
        В&nbsp;2020 году ВКС Корпуса стражей исламской революции запустили первый военный спутник &laquo;Нур-1&raquo;, через два года&nbsp;&mdash; второй, а&nbsp;затем и&nbsp;третий. Все три запуска
        были осуществлены с&nbsp;помощью ракеты-носителя &laquo;Касед&raquo;, сконструированной и&nbsp;произведенной также специалистами ВКС КСИР.
      </p>
      <p class="text-wrapper">
        Сегодня Иран работает над разработкой ракеты-носителя &laquo;Сарир&raquo;, которая позволит выводить спутники на&nbsp;геостационарную орбиту, а&nbsp;также продолжает модернизировать уже
        показавшую себя ракету-носитель &laquo;Симург&raquo;.
      </p>
    </div>
    <!--    END Section 2-->

    <timeline :menu="menu" v-transfer-dom></timeline>
  </div>
</template>

<style scoped lang="scss">
.block-chapter {
  position: relative;
  background: var(--darkColor);
  color: var(--darkColor);


  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("~@/assets/img/noise_2.webp");
    opacity: .4;
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg,  rgba( #FADBA1, .9) 30%,  var(--darkGoldColor) 40% );
    opacity: .6;
    z-index: -1;
  }
}

::v-deep {
  h2, .subheading {
    color: var(--darkColor);
  }
}


h3, h4 {
  color: var(--lightColor);
}

::v-deep blockquote, .description-photo {
  color: var(--lightColor)
}

.block-photo {
  margin-bottom: -5vw;
}
</style>
