<template>
  <div id="app">
    <stars></stars>
    <header-component v-transfer-dom :class="{'show': !isLoading}"></header-component>
    <mainComponent :isLoading="isLoading"></mainComponent>

    <template v-if="!isLoading">
      <first-charter></first-charter>
      <second-chapter></second-chapter>
      <third-chapter></third-chapter>
      <fourth-chapter></fourth-chapter>
      <fifth-chapter></fifth-chapter>
    </template>
  </div>
</template>

<style lang="scss">
@import "./assets/style/style";
#app {

}
</style>
<script>
import mainComponent from "@/components/Main.vue";
import firstCharter from "@/components/chapters/firstCharter.vue";
import secondChapter from "@/components/chapters/secondChapter.vue";
import thirdChapter from "@/components/chapters/thirdChapter.vue";
import fourthChapter from "@/components/chapters/fourthChapter.vue";
import HeaderComponent from "@/components/layouts/headerComponent.vue";
import Stars from "@/components/effects/stars.vue";
import PreloaderComponent from "@/components/layouts/preloaderComponent.vue";
import FifthChapter from "@/components/chapters/fifthChapter.vue";

export default {
  name: 'App',
  components: {
    FifthChapter,
    PreloaderComponent,
    Stars,
    HeaderComponent,
    mainComponent,
    firstCharter,
    secondChapter,
    thirdChapter,
    fourthChapter
  },
  data() {
    return {
      isLoading: true,
    }
  },
  methods: {},

  created() {
    window.scrollTo(0, 0);
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
      document.body.classList.remove('overflow');
    }, 3000)
  },
}

</script>
