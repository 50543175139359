<script>
import {defineComponent} from 'vue'
import titleSection from "@/components/blocks/titleSection.vue";
import {infinityScrollPage} from "@/assets/js/infinityScroll";
import Timeline from "@/components/layouts/timeline/timelineWrapper.vue";
import BlockPhoto from "@/components/blocks/blockPhoto.vue";
import VideoComponent from "@/components/blocks/videoComponent.vue";

export default defineComponent({
  name: "thirdChapter",
  components: {VideoComponent, BlockPhoto, Timeline, titleSection},
  data() {
    return {
      menu: [
        {
          link: 'USA',
          title: 'Соединенные Штаты Америки',
        },
        {
          link: 'China',
          title: 'Китайская Народная Республика',
        },
        {
          link: 'Russia',
          title: 'Российская Федерация',
        },
      ]
    }
  },
  mounted() {
    infinityScrollPage(this.$el)
  }
})
</script>

<template>
  <div class="block-chapter">
    <video-component src="assets/video/chapter_3.webm" poster="assets/video/chapter_3.webp"></video-component>
    <title-section src="militarizatsiya-kosmosa">
      <template v-slot:title>Милитаризация космоса</template>
      <template v-slot:subheading>
        В&nbsp;условиях глобальной геополитической напряженности перед странами встает вопрос об&nbsp;обеспечении государственной безопасности. При ведении боевых действий применяются различные
        тактики гибридной войны, которая подразумевает, в&nbsp;том числе, использование космических аппаратов для эффективного планирования операций с&nbsp;учетом местности, коммуникации между
        подразделениями, нанесения ракетных ударов и&nbsp;наблюдения за&nbsp;местами передвижения и&nbsp;дислокации сил противника.
      </template>
    </title-section>

    <!--    Section 1-->
    <div class="horizontal-scroll_wrapper">
      <div class="container">
        <block-photo img="assets/img/usa.webp"></block-photo>
        <block-photo img="assets/img/usa.png" class="photo-bg"></block-photo>
        <h3 class="text-wrapper" id="USA">Соединённые Штаты Америки</h3>
        <p class="text-wrapper">
          Согласно Обзору космической политики и&nbsp;защиты спутников, составленному министром обороны и&nbsp;директором национальной разведки США, и&nbsp;Стратегии национальной безопасности США 2022&nbsp;года,
          Соединенные Штаты подчеркивают особую роль доминирования в&nbsp;космическом пространстве в&nbsp;качестве инструмента модернизации и&nbsp;укрепления вооруженных сил, а&nbsp;также получения
          экономических выгод и&nbsp;технологического суверенитета.
        </p>
        <p class="text-wrapper">
          Во&nbsp;время операции &laquo;Буря в&nbsp;пустыне&raquo; против Ирака в&nbsp;1991 году США использовали не&nbsp;только обычное вооружение, но&nbsp;и&nbsp;задействовали средства космической
          разведки, в&nbsp;том числе глобальную систему позиционирования (GPS). Это стало первым случаем применения аэрокосмических технологий в&nbsp;боевых действиях.
        </p>
        <p class="text-wrapper">
          В&nbsp;США разрабатываются спутники, способные проводить аэрокосмическую фотосъемку. Так, с&nbsp;1976 года и&nbsp;по&nbsp;сей день Соединенные Штаты запускают аппараты серии KH-11&nbsp;KENNEN,
          основная особенность которых заключается в&nbsp;скорости передачи изображений. Группы разведывательных KH-11 участвовали во&nbsp;многих случаях шпионажа, в&nbsp;том числе, им&nbsp;удалось
          сфотографировать Космический центр Имама Хомейни в&nbsp;Иране.
        </p>
      </div>
      <!--    END Section 1-->

      <!--    Section 2-->
      <div class="container">
        <block-photo img="assets/img/china.webp"></block-photo>
        <block-photo img="assets/img/china.png" class="photo-bg"></block-photo>
        <h3 class="text-wrapper" id="China">Китайская Народная Республика</h3>
        <p class="text-wrapper">
          Китай начал развивать свою космическую программу в&nbsp;1956&nbsp;году, однако сверхдержавой в&nbsp;этой области он&nbsp;стал лишь в&nbsp;2003-м году. Проведя успешные испытания
          противоспутниковой ракеты в&nbsp;2007&nbsp;году, Китай продолжил совершенствовать данное направление, позднее осуществив серию испытаний противоспутниковых перехватчиков серии Dong Neng.
        </p>
        <p class="text-wrapper">
          В&nbsp;целях укрепления своей аэрокосмической мощи Китаем была разработана собственная технология оружия направленной энергии, в&nbsp;которую могут входить мощные лазеры, лучевое оружие или
          волновые излучатели, например, &laquo;релятивистский клистронный усилитель&raquo;. Одно из&nbsp;основных применений подобных наземных станций совместно с&nbsp;радарами заключается в&nbsp;определении
          и&nbsp;точечном уничтожении космического мусора, однако очень вероятно, что подобная техника в&nbsp;дальнейшем будет использоваться для поражения или вывода из&nbsp;строя космических
          аппаратов.
        </p>
        <p class="text-wrapper">
          Предположительно, у&nbsp;Китая имеются также космические аппараты двойного назначения. В&nbsp;июне 2022 года КНР запустила 3&nbsp;спутника серии Yaogan-35, которые предназначены для
          дистанционного зондирования Земли. При этом в&nbsp;марте того&nbsp;же года Китай вывел на&nbsp;орбиту еще 22&nbsp;объекта с&nbsp;тем&nbsp;же назначением. Однако западные эксперты отмечают,
          что эти спутники не&nbsp;только выполняют исследования земной поверхности, но&nbsp;и&nbsp;имеют возможность проводить разведку. В&nbsp;свою очередь Китайская аэрокосмическая
          научно-техническая корпорация планировала вывести на&nbsp;орбиту около 200 спутников в&nbsp;течение 2023&nbsp;года.
        </p>
      </div>
      <!--    END Section 2-->

      <!--    Section 3-->
      <div class="container">
        <block-photo img="assets/img/russia.webp"></block-photo>
        <block-photo img="assets/img/russia.png" class="photo-bg"></block-photo>
        <h3 class="text-wrapper" id="Russia">Российская Федерация</h3>
        <p class="text-wrapper">
          Будучи правопреемницей СССР и&nbsp;его космических разработок, Россия продолжила развиваться в&nbsp;статусе космической державы. В&nbsp;Федеральной космической программе до&nbsp;2025 года
          предусматривается наращивание орбитальной группировки космических аппаратов, предназначенных как для научных и&nbsp;социально-экономических, так и&nbsp;для военных целей. Например, спутники
          серии &laquo;Лотос-С&raquo; и&nbsp;серии &laquo;Пион-НКС&raquo; предназначены для функционирования системы глобальной спутниковой морской космической разведки и&nbsp;целеуказания &laquo;Лиана&raquo;.
          Подобная система, осуществляя радиотехническую и&nbsp;радиолокационную разведку, способна обнаружить местонахождение вражеских военных кораблей, подводных лодок и&nbsp;наземных целей.
        </p>
        <p class="text-wrapper">
          Наряду с&nbsp;Китаем и&nbsp;США, в&nbsp;России также занимаются производством и&nbsp;запуском спутников дистанционного зондирования земной поверхности различных моделей. Например,
          космический аппарат &laquo;Кондор-ФКА&raquo; и&nbsp;аналогичная модель &laquo;Обзор-Р&raquo; способны осуществлять экологический мониторинг поверхности земли и&nbsp;океанов, наблюдение за&nbsp;гидрометеорологической
          обстановкой с&nbsp;помощью радиолокационных съемок и&nbsp;получать изображения разнообразных разрешений. Западными специалистами считается, что, как и&nbsp;любые спутники&nbsp;РФ, выведенные
          на&nbsp;орбиту, данные объекты будут задействованы в&nbsp;разведывательных и&nbsp;коммуникационных целях.
        </p>
        <p class="text-wrapper">
          Космические аппараты серии &laquo;Репей&raquo;, произведенные &laquo;Информационными спутниковыми системами имени Решетнева&raquo;, прямо обозначаются как спутники с&nbsp;функциями
          радиоэлектронной разведки. Предполагается, что подобные аппараты будут иметь возможность проводить наблюдение в&nbsp;режиме реального времени, осуществлять поиск военной техники и&nbsp;радиолокационных
          станций в&nbsp;любом регионе мира. В&nbsp;США существуют космические аппараты с&nbsp;таким&nbsp;же функционалом, однако, по&nbsp;заявлениям некоторых военных экспертов, разработка &laquo;ИСС
          им. Решетова&raquo; является передовой в&nbsp;данной области.
        </p>
      </div>
      <!--    END Section 3-->
    </div>
    <timeline :menu="menu" v-transfer-dom></timeline>
  </div>
</template>

<style scoped lang="scss">
.block-chapter {
  position: relative;
  background: var(--darkColor);
  color: var(--lightColor);
  $timeAnimation: 20s;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("~@/assets/img/noise.webp");
    pointer-events: none;
    opacity: .25;
    z-index: -1;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--redColor);
    opacity: .6;
    z-index: -1;
  }
}

.block-photo {
  margin-bottom: -1vw;
}

.photo-bg {
  margin-top: -20rem;
  margin-bottom: -30rem;
  opacity: .2;
  z-index: -1;
}
</style>
