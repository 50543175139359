<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "blockPhoto",
  props: {
    img: null,
  }
})
</script>

<template>
  <div class="block-photo">
    <img
        :src='require(`@/${img}`)'
        alt=""
        v-if="img">
    <div class="photo-sign">
      <slot name="sign"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@/assets/style/utils/sizes";

.block-photo {
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;

  img {
    width: 140%;
    //animation: 10s resizeImg infinite alternate;


    @media (max-width: $mobile) {
      width: 165%;
      margin-left: -1rem;
    }
  }
}


@keyframes resizeImg {
  from {
    transform: scale(1) rotate(0deg);
  }

  to {
    transform: scale(1.05) rotate(-1deg);
  }
}
</style>
